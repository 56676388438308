/* You can add global styles to this file, and also import other style files */
@import 'material-icons/iconfont/material-icons.css';

html {
    height: 100%;
    font-size: 100%;
}
body {
    height: 100%;
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 2rem;
}

.sticky-form-buttons {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    z-index: 10;
}
.sticky-form-buttons > * {
    margin-right: 1rem;
    margin-bottom: 1rem;
}



.sticky-form-buttons-left {
    display: flex;
    //justify-content: flex;
    position: sticky;
    top: 0;
    z-index: 10;
}
.sticky-form-left {
    margin-right: 1rem;
    margin-bottom: 1rem;
}



.mat-mdc-header-cell {
    font-weight: bold;
}

.mat-column-tools {
    width: 1%;
    white-space: nowrap;
}

button.left-space {
    margin-left: 1rem;
}


/*
 Helper for sticky headers on tables
*/
table.sticky-table {
    position: relative;
    display: block;
}
table.sticky-table th {
    position: sticky;
    top: 0;
    font-weight: bold;
    z-index: 10;
}


table.color-table tr:nth-child(even) {
    background-color: #f8f8f8;
}
